@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

@tailwind utilities;
@tailwind components;
@tailwind base;

:root {
  --font-condensed: "Roboto Condensed";
}

html {
  font-size: clamp(10px, 2vmin + 1px, 18px);
}
body {
  @apply text-base;
}
a:hover {
  text-decoration: underline;
}

h1 {
  font-family: var(--font-condensed);
  font-weight: bold;
  @apply text-4xl;
}

.logo {
  font-family: var(--font-condensed);
}

.datagrid p {
  line-height: 1.9rem;
  @apply text-ellipsis whitespace-nowrap overflow-hidden;
}
.datagrid .activityDataLabel {
  @apply text-right text-slate-600;
}
.value {
  @apply font-semibold;
}
.unit {
  @apply font-normal text-xs pl-0.5;
}

.datagrid > div.button {
  font-family: var(--font-condensed);
}
.datagrid > div.filter {
  min-height: 1rem;
  line-height: 1rem;
}
.dataRow > * {
  @apply pt-2 pb-2;
}
.dataRow:hover > * {
  @apply bg-red-600 text-white;
}
.shade {
  background-color: rgba(0, 0, 0, 0.8);
}

.dateSlider {
}
.dateSliderThumb {
  @apply text-xs font-bold w-10 text-center mt-1 pt-1 pb-1 text-white bg-red-600 cursor-pointer;
}
.dateSliderTrack {
  @apply h-4 mt-4 ml-2 mr-2 bg-slate-200;
}
